const getAllCaptionText = caption => {
  let captionText;
  const textBlocks = caption.blocks;
  if (textBlocks.length > 1) {
    const captionTextArray = [];
    textBlocks.forEach(item =>
      item.text === '' ? captionTextArray.push('\n\n') : captionTextArray.push(item.text)
    );
    captionText = captionTextArray.join('');
  } else {
    captionText = textBlocks[0].text;
  }
  return captionText;
};

export default getAllCaptionText;
